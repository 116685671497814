body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%
}

.notistack-SnackbarContainer.snack {
  top: 40px
}

.MuiDataGrid-columnHeader.nopad,
.MuiDataGrid-cell.nopad,
.nopad {
  padding: 0
}

.link {
  text-transform: none;
  text-decoration: underline;
  color: black;
  font-weight: bold;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
}

.link:hover {
  text-decoration: none;
}
